<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Buy from "@/components/widgets/store-buy";

export default {
  locales: {
    pt: {
    },
    es: {
      Buy: "Comprar",
    },
  },
  components: {
    Layout,
    Buy,
  },
  data() {
    return {
      partner: null,

      product: {
        modal: false,

        id: null,
        variations: null,
        images: [],
        name: null,
        description: null,
      },

      products: {
        loading: true,
        errored: false,
        empty: false,
        list: null,
      },
    };
  },
  methods: {
    getPartner() {
      this.partner = null;
      this.products.loading = true;
      this.products.errored = null;
      this.products.empty = false;
      this.products.list = null;

      api
        .get("inventory/partners/" + this.$route.params.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.partner = response.data.partner;

            this.products.list = response.data.products;
            this.products.loading = false;
          }
        })
        .catch((error) => {
          if (error) {
            this.$router.push('/inventory/partners');
          }
        })
        .finally(() => {
          this.products.loading = false;
        });
    },
    showProduct(product) {
      this.product.modal = true;
      this.product.id = product.id;
      this.product.variations = product.variations;
      this.product.images = product.images;
      this.product.name = product.name;
      this.product.description = product.description;
    },
  },
  mounted() {
    this.getPartner();
  },
};
</script>

<template>
  <Layout ref="store">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/store">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/store">Reposição</router-link></li>
      <li class="breadcrumb-item"><router-link tag="a" to="/inventory/partners">Parceiros</router-link></li>
    </ol>

    <div v-if="!partner" class="text-center">
      <b-spinner variant="default" role="status"></b-spinner>
    </div>

    <div v-else-if="partner && partner.length == 0">
      <div class="card">
        <div class="card-body text-center">
          Nenhum informativo encontrado.
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="partner" class="card">
        <div class="card-body card-partner">
          <div class="d-flex align-items-center">
            <div class="logo">
              <img v-if="partner.image.logo" :src="partner.image.logo">
              <img v-else src="@/assets/images/logo-app.png" />
            </div>
            <div>
              <h5 :class="!partner.description ? 'mb-0' : ''">{{ partner.name }}</h5>
              <p v-if="partner.description" class="mb-0">{{ partner.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!products.list || (products.list && products.list.length == 0)" class="card">
        <div class="card-body">
          <div class="text-center py-5">
            <i class="far fa-sad-cry font-size-24"></i><br />
            ainda não temos nenhum produto disponível para este parceiro
          </div>
        </div>
      </div>

      <div v-else class="row">
        <div class="col-md-6 col-lg-4" v-for="(product, id) in products.list" :key="id">
          <div v-if="product.instock == 'yes'">
            <div class="card card-h-fix">
              <div class="card-body text-center">
                <div v-if="product.partner && product.partner.logo" style="position: absolute; top: -10px; right: 10px; z-index: 10;">
                  <img :src="product.partner.logo" class="rounded-circle" style="max-width: 80px;" />
                </div>
                <div class="mb-3">
                  <b-carousel style="margin: 0 auto; max-width: 350px" controls indicators fade :interval="0">
                    <b-carousel-slide style="max-width: 350px; min-height: 200px" v-for="(image, i) in product.images" :key="i" :img-src="image"></b-carousel-slide>
                  </b-carousel>
                </div>
                <div class="font-weight-bold text-dark">
                  {{ product.name }}
                  <button class="btn btn-link p-0 text-dark" @click="showProduct(product)">
                    <i class="bx bx-info-circle font-size-22"></i>
                  </button>
                </div>
                <div class="row justify-content-center mt-3">
                  <div class="d-none col-6 text-center">
                    <h5 class="mb-0 font-size-16">
                      {{ product.price.consumer | currency }}
                    </h5>
                    <span class="align-self-center font-size-11 bg-soft-light rounded p-1 text-uppercase">Consumidor</span>
                  </div>
                  <div class="col-6 text-center">
                    <h5 class="mb-0 font-size-16">
                      {{ product.price.affiliate | currency }}
                    </h5>
                  </div>
                </div>
                <Buy :id="product.id" :variations="product.variations" class="mt-3"></Buy>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="card card-h-fix">
              <div class="card-body text-center">
                <div v-if="product.partner && product.partner.logo" style="position: absolute; top: -10px; right: 10px; z-index: 10;">
                  <img :src="product.partner.logo" class="rounded-circle" style="max-width: 80px;" />
                </div>
                <div class="mb-3">
                  <b-carousel style="margin: 0 auto; max-width: 350px" controls indicators fade :interval="0">
                    <b-carousel-slide style="
                      max-width: 350px;
                      min-height: 200px;
                      filter: grayscale(100%);
                    " v-for="(image, i) in product.images" :key="i" :img-src="image"></b-carousel-slide>
                  </b-carousel>
                </div>
                <div class="font-weight-bold text-dark">
                  {{ product.name }}
                  <button class="btn btn-link p-0 text-dark" @click="showProduct(product)">
                    <i class="bx bx-info-circle font-size-22"></i>
                  </button>
                </div>
                <div class="row justify-content-center mt-3">
                  <div class="d-none col-6 text-center">
                    <h5 class="mb-0 font-size-16">
                      {{ product.price.consumer | currency }}
                    </h5>
                    <span class="align-self-center font-size-11 bg-soft-light rounded p-1 text-uppercase">Consumidor</span>
                  </div>
                  <div class="col-6 text-center">
                    <h5 class="mb-0 font-size-16">
                      {{ product.price.affiliate | currency }}
                    </h5>
                  </div>
                </div>
                <b-button disabled block class="mt-3">INDISPONÍVEL</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="product.modal" hide-footer centered>
      <div class="mb-3">
        <b-carousel style="margin: 0 auto; max-width: 350px" controls indicators fade :interval="0">
          <b-carousel-slide style="max-width: 350px; min-height: 200px" v-for="(image, i) in product.images" :key="i" :img-src="image"></b-carousel-slide>
        </b-carousel>
      </div>
      <h4 class="font-weight-bold mb-3">{{ product.name }}</h4>
      <div v-html="product.description"></div>
    </b-modal>
  </Layout>
</template>

<style scoped>
.card-partner .d-flex {
  gap: 10px;
}
.card-partner .logo img {
  border-radius: 50%;
  width: 100px;
}
</style>
